<template>
  <b-input-group :style="$isMobile ? 'width: 100%;' : 'width: 30em;'">
    <b-form-input
      placeholder="type to search"
      v-model="searchValue"
      @keyup.enter="onSearchClick"
    />

    <b-input-group-append>
      <b-button variant="outline-light" @click="onSearchClick">
        <font-awesome-icon icon="search" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderSearchBox",
  components: {},
  data: () => {
    return {
      searchValue: ""
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  created() {},

  methods: {
    onSearchClick() {
      if (this.searchValue.trim() === "") {
        this.$form.makeToastError("Search value is empty");
        return;
      }

      if (this.$route.path !== `/search-results/${this.searchValue}`) {
        this.$router.push({
          path: `/search-results/${this.searchValue}`,
          params: {
            query: this.searchValue
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>

<template>
  <AppHeaderDropdown no-caret right>
    <template slot="header">
      <img src="img/avatars/avatar2.png" class="img-avatar" /> </template
    >\
    <template slot="dropdown">
      <b-dropdown-header>
        Settings
      </b-dropdown-header>
      <b-dropdown-item @click="profile">
        <i class="fa fa-user" /> Profile
      </b-dropdown-item>
      <!--
      <b-dropdown-item @click="deleteCache">
        <i class="fa fa-trash" /> Cleanup cache
      </b-dropdown-item>
-->
      <!--   <b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item> -->
      <b-dropdown-divider />
      <b-dropdown-item @click="logout">
        <i class="fa fa-lock" /> Logout
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { AUTH_LOGOUT } from "../store/actions/auth";

import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";

export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {
      itemsCount: 0
    };
  },
  computed: {},
  methods: {
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/login");
      });
    },
    profile: function() {
      this.$router.push("/settings/profile");
    },
    deleteCache() {
      //window.location.reload(true);
    }
  }
};
</script>

<style scoped>
.dropdown-header {
  background-color: white;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    attrs: {
      "right": _vm.right,
      "no-caret": _vm.noCaret
    }
  }, [_c('template', {
    slot: "button-content"
  }, [_vm._t("header", function () {
    return [_vm._v(" ❔ ")];
  })], 2), _vm._t("dropdown", function () {
    return [_c('div', {
      style: {
        right: 'auto',
        height: '200px'
      }
    }, [_c('span', {
      staticClass: "text-center"
    }, [_vm._v("dropdown")])])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
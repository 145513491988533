var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "sidebar-nav"
  }, [_c('VuePerfectScrollbar', {
    staticClass: "scroll-area",
    attrs: {
      "settings": _vm.psSettings
    },
    on: {
      "ps-scroll-y": _vm.scrollHandle
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    ref: "menuSearch",
    class: [_vm.searchValue !== '' ? 'highlight' : ''],
    attrs: {
      "placeholder": "type to search menu"
    },
    on: {
      "blur": _vm.onSearchBlur
    },
    model: {
      value: _vm.searchValue,
      callback: function callback($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }), _c('b-input-group-append', [_vm.searchValue !== '' ? _c('b-button', {
    attrs: {
      "variant": "light",
      "squared": ""
    },
    on: {
      "click": function click($event) {
        _vm.searchValue = '';
      }
    }
  }, [_vm._v(" x ")]) : _vm._e()], 1)], 1), _c('ul', {
    staticClass: "nav"
  }, [_vm._l(_vm.filteredMenu, function (item, index) {
    return [item.title ? [item.visible ? _c('SidebarNavTitle', {
      key: index,
      attrs: {
        "name": item.name,
        "classes": item.class,
        "wrapper": item.wrapper
      }
    }) : _vm._e()] : item.divider ? [_c('SidebarNavDivider', {
      key: index,
      attrs: {
        "classes": item.class
      }
    })] : item.label ? [item.visible ? _c('SidebarNavLabel', {
      key: index,
      attrs: {
        "name": item.name,
        "url": item.url,
        "icon": item.icon,
        "label": item.label,
        "classes": item.class
      }
    }) : _vm._e()] : [item.children ? [_c('SidebarNavDropdown', {
      key: index,
      attrs: {
        "name": item.name,
        "url": item.url,
        "icon": item.icon
      }
    }, [_vm._l(item.children, function (childL1, index1) {
      return [childL1.children ? [_c('SidebarNavDropdown', {
        key: index1,
        attrs: {
          "name": childL1.name,
          "url": childL1.url,
          "icon": childL1.icon
        }
      }, _vm._l(childL1.children, function (childL2, index2) {
        return _c('li', {
          key: index2,
          staticClass: "nav-item"
        }, [_c('SidebarNavLink', {
          attrs: {
            "name": childL2.name,
            "url": childL2.url,
            "icon": childL2.icon,
            "badge": childL2.badge,
            "variant": childL2.variant,
            "attributes": childL2.attributes
          }
        })], 1);
      }), 0)] : [_c('SidebarNavItem', {
        key: index1,
        attrs: {
          "classes": item.class
        }
      }, [_c('SidebarNavLink', {
        attrs: {
          "name": childL1.name,
          "url": childL1.url,
          "icon": childL1.icon,
          "badge": childL1.badge,
          "variant": childL1.variant,
          "attributes": childL1.attributes
        }
      })], 1)]];
    })], 2)] : [_c('SidebarNavItem', {
      key: index,
      attrs: {
        "classes": item.class
      }
    }, [_c('SidebarNavLink', {
      attrs: {
        "name": item.name,
        "url": item.url,
        "icon": item.icon,
        "badge": item.badge,
        "variant": item.variant,
        "attributes": item.attributes
      }
    })], 1)]]];
  })], 2), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
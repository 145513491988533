var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: _vm.classList
  });

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppHeaderDropdown', {
    staticClass: "timetrack-dropdown",
    attrs: {
      "right": ""
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('font-awesome-icon', {
    style: _vm.timeTracker.isTimeTracking ? {
      color: 'lime'
    } : {
      color: 'gray'
    },
    attrs: {
      "icon": "clock",
      "size": "1x"
    }
  })], 1), _c('template', {
    slot: "dropdown"
  }, [_c('b-dropdown-header', [_vm._v(" Time tracking ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": ""
    }
  }, [_c('b-container', {
    staticClass: "time-track-container"
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "5"
    }
  }, [_vm._v(" Now " + _vm._s(_vm.$clientTZ) + ": ")]), _c('b-col', [_vm._v(" " + _vm._s(_vm.nowClient))])], 1), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "5"
    }
  }, [_vm._v(" Status: ")]), _c('b-col', [_vm._v(_vm._s(_vm.trackStatus))])], 1), _vm.timeTracker.isTimeTracking ? _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "5"
    }
  }, [_vm._v(" Started at: ")]), _c('b-col', [_vm._v(_vm._s(_vm.startedAt))])], 1) : _vm._e(), _vm.timeTracker.isTimeTracking ? _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "5"
    }
  }, [_vm._v(" Total: ")]), _c('b-col', [_vm._v(_vm._s(_vm.totalTime))])], 1) : _vm._e(), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "5"
    }
  }, [_vm._v(" Week: ")]), _c('b-col', [_vm._v(_vm._s(_vm.week))])], 1), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "5"
    }
  }, [_vm._v(" Location: ")]), _c('b-col', [_vm._v(_vm._s(_vm.location))])], 1)], 1)], 1), _c('hr'), !_vm.isUserClockedIn ? _c('b-dropdown-item', {
    on: {
      "click": _vm.clock_In
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "sign-in-alt"
    }
  }), _vm._v(" Clock in ")], 1) : _vm._e(), _vm.isUserClockedIn ? _c('b-dropdown-item', {
    on: {
      "click": _vm.lunch
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "utensils"
    }
  }), _vm._v(" Lunch ")], 1) : _vm._e(), _vm.isUserClockedIn ? _c('b-dropdown-item', {
    on: {
      "click": _vm.clock_Out
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "sign-out-alt"
    }
  }), _vm._v(" Clock out ")], 1) : _vm._e(), _c('clock-time-dialog', {
    ref: "clockTimeDialog",
    on: {
      "save": _vm.onDialogSave
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppHeaderDropdown', {
    ref: "app-header-dd",
    staticClass: "timetrack-dropdown",
    attrs: {
      "right": ""
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('font-awesome-icon', {
    staticStyle: {
      "color": "gray"
    },
    attrs: {
      "icon": "plus-circle"
    }
  })], 1), _c('template', {
    slot: "dropdown"
  }, [_c('b-dropdown-header', [_vm._v(" Quick create ")]), _c('b-container', {
    staticClass: "quick-add-container"
  }, [_c('b-row', {
    attrs: {
      "no-glutters": ""
    }
  }, [_c('b-col', [_c('b-button', {
    staticClass: "top-menu-btn m-1",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "block": "",
      "squared": "",
      "variant": "light"
    },
    on: {
      "click": _vm.createActivity
    }
  }, [_c('font-awesome-icon', {
    staticStyle: {
      "color": "gray"
    },
    attrs: {
      "icon": "clipboard-list"
    }
  }), _vm._v(" Activity ")], 1)], 1), _c('b-col', [_c('b-button', {
    staticClass: "top-menu-btn m-1",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "block": "",
      "squared": "",
      "variant": "light"
    },
    on: {
      "click": _vm.createTask
    }
  }, [_c('font-awesome-icon', {
    staticStyle: {
      "color": "gray"
    },
    attrs: {
      "icon": "tasks"
    }
  }), _vm._v(" Task ")], 1)], 1)], 1), _c('b-row', {
    attrs: {
      "no-glutters": ""
    }
  }, [_c('b-col', [_c('b-button', {
    staticClass: "top-menu-btn m-1",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "block": "",
      "squared": "",
      "variant": "light"
    },
    on: {
      "click": _vm.createReceipt
    }
  }, [_c('font-awesome-icon', {
    staticStyle: {
      "color": "gray"
    },
    attrs: {
      "icon": "receipt"
    }
  }), _vm._v(" Receipt ")], 1)], 1), _c('b-col', [_c('b-button', {
    staticClass: "top-menu-btn m-1",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "block": "",
      "squared": "",
      "variant": "light"
    },
    on: {
      "click": _vm.createAccount
    }
  }, [_c('font-awesome-icon', {
    staticStyle: {
      "color": "gray"
    },
    attrs: {
      "icon": "building"
    }
  }), _vm._v(" Account ")], 1)], 1)], 1), _c('b-row', {
    attrs: {
      "no-glutters": ""
    }
  }, [_c('b-col', [_c('b-button', {
    staticClass: "top-menu-btn",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "block": "",
      "squared": "",
      "variant": "light"
    },
    on: {
      "click": _vm.createProject
    }
  }, [_c('font-awesome-icon', {
    staticStyle: {
      "color": "gray"
    },
    attrs: {
      "icon": "project-diagram"
    }
  }), _vm._v(" Project ")], 1)], 1), _c('b-col', [_c('b-button', {
    staticClass: "top-menu-btn m-1",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "block": "",
      "squared": "",
      "variant": "light"
    },
    on: {
      "click": _vm.createContact
    }
  }, [_c('font-awesome-icon', {
    staticStyle: {
      "color": "gray"
    },
    attrs: {
      "icon": "address-card"
    }
  }), _vm._v(" Contact ")], 1)], 1)], 1)], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SidebarNavItem', {
    attrs: {
      "classes": _vm.classList.navItem
    }
  }, [_c('a', {
    class: _vm.classList.navLink,
    attrs: {
      "href": _vm.url
    }
  }, [_c('i', {
    class: _vm.classList.icon
  }), _vm._v(" " + _vm._s(_vm.name) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
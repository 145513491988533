var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppHeaderDropdown', {
    attrs: {
      "no-caret": "",
      "right": ""
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/avatar2.png"
    }
  })]), _vm._v("\\ "), _c('template', {
    slot: "dropdown"
  }, [_c('b-dropdown-header', [_vm._v(" Settings ")]), _c('b-dropdown-item', {
    on: {
      "click": _vm.profile
    }
  }, [_c('i', {
    staticClass: "fa fa-user"
  }), _vm._v(" Profile ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('i', {
    staticClass: "fa fa-lock"
  }), _vm._v(" Logout ")])], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <router-link
    tag="li"
    class="nav-item nav-dropdown"
    :to="url"
    disabled
    v-slot="{ navigate }"
  >
    <span @click="navigate" @keypress.enter="navigate" role="link">
      <div class="nav-link nav-dropdown-toggle" @click="handleClick">
        <i :class="classIcon"></i> {{ name }}
      </div>
      <ul class="nav-dropdown-items">
        <slot></slot>
      </ul>
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  },
  computed: {
    classIcon() {
      return ["nav-icon", this.icon];
    }
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle("open");
    }
  }
};
</script>

<style scoped lang="css">
::v-deep .nav-link {
  cursor: pointer;
}
</style>

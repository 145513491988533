var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.classList
  }, [_vm._t("default", function () {
    return [_vm._v("Callout")];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isDisabled ? _c('a', _vm._b({
    class: _vm.classList,
    attrs: {
      "tabindex": "-1"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    }
  }, 'a', _vm.attributes, false), [_c('i', {
    class: _vm.classIcon
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.name))]), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1) : _vm.isExternalLink ? _c('a', _vm._b({
    class: _vm.classList,
    attrs: {
      "href": _vm.url
    }
  }, 'a', _vm.attributes, false), [_c('i', {
    class: _vm.classIcon
  }), _vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1) : _c('router-link', _vm._b({
    class: _vm.classList,
    attrs: {
      "to": _vm.url
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var navigate = _ref.navigate;
        return [_c('span', {
          attrs: {
            "role": "link"
          },
          on: {
            "click": navigate,
            "keypress": function keypress($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return navigate.apply(null, arguments);
            }
          }
        }, [_c('i', {
          class: _vm.classIcon
        }), _vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
          attrs: {
            "variant": _vm.badge.variant
          }
        }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1)];
      }
    }])
  }, 'router-link', _vm.attributes, false));

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- no-caret -->
  <AppHeaderDropdown right class="timetrack-dropdown">
    <template slot="header">
      <font-awesome-icon
        icon="clock"
        size="1x"
        :style="
          timeTracker.isTimeTracking ? { color: 'lime' } : { color: 'gray' }
        "
      />
    </template>
    <template slot="dropdown">
      <b-dropdown-header>
        Time tracking
      </b-dropdown-header>
      <b-dropdown-item disabled>
        <b-container class="time-track-container">
          <b-row no-gutters>
            <b-col cols="5" class="font-weight-bold">
              Now {{ $clientTZ }}:
            </b-col>
            <b-col> {{ nowClient }}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="5" class="font-weight-bold">
              Status:
            </b-col>
            <b-col>{{ trackStatus }}</b-col>
          </b-row>
          <b-row v-if="timeTracker.isTimeTracking" no-gutters>
            <b-col cols="5" class="font-weight-bold">
              Started at:
            </b-col>
            <b-col>{{ startedAt }}</b-col>
          </b-row>
          <b-row v-if="timeTracker.isTimeTracking" no-gutters>
            <b-col cols="5" class="font-weight-bold">
              Total:
            </b-col>
            <b-col>{{ totalTime }}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="5" class="font-weight-bold">
              Week:
            </b-col>
            <b-col>{{ week }}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="5" class="font-weight-bold">
              Location:
            </b-col>
            <b-col>{{ location }}</b-col>
          </b-row>
        </b-container>
      </b-dropdown-item>
      <hr />
      <b-dropdown-item v-if="!isUserClockedIn" @click="clock_In">
        <font-awesome-icon icon="sign-in-alt" /> Clock in
      </b-dropdown-item>
      <b-dropdown-item v-if="isUserClockedIn" @click="lunch">
        <font-awesome-icon icon="utensils" /> Lunch
      </b-dropdown-item>
      <b-dropdown-item v-if="isUserClockedIn" @click="clock_Out">
        <font-awesome-icon icon="sign-out-alt" /> Clock out
      </b-dropdown-item>

      <clock-time-dialog ref="clockTimeDialog" @save="onDialogSave" />
    </template>
  </AppHeaderDropdown>
</template>

<script>
import moment from "moment";

import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";

import ClockTimeDialog from "@/components/ClockTimeDialog";

import { TRACK_TIME_ACTIONS } from "@/shared/constants";

import { mapState, mapActions } from "vuex";

const updateTimeInterval = 1000;
const updateTimeTrackingStatusInterval = 5000;
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown,
    ClockTimeDialog
  },
  data: () => {
    return {
      itemsCount: 0,
      nowUTC: "",
      nowClient: "",
      location: "",
      timer: {
        updateTime: undefined,
        updateTimeTrackingStatus: undefined
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      timeTracker: state => state.timeTracker
    }),
    isUserClockedIn() {
      return this.$store.getters["timeTracker/isTimeTracking"];
    },

    startedAt() {
      return `${this.timeTracker.data.trackdate} ${this.timeTracker.data.tracktime}`;
    },

    week() {
      return moment().isoWeek();
    },
    totalTime() {
      let start = moment(
        `${this.timeTracker.data.trackdate} ${this.timeTracker.data.tracktime}`
      );
      let end = moment(this.nowClient);
      let diff = end.diff(start);
      let f = moment.utc(diff).format("HH:mm:ss");

      return f;
    },

    trackStatus() {
      return this.timeTracker.isTimeTracking ? "Enabled" : "Disabled";
    }
  },
  created() {
    this.getLocation();
    this.timer.updateTime = setInterval(this.updateTime, updateTimeInterval);

    this.timer.updateTimeTrackingStatus = setInterval(
      this.updateTimeTrackingStatus,
      updateTimeTrackingStatusInterval
    );

    this.updateTime();
  },

  methods: {
    ...mapActions("timeTracker", ["clockIn", "clockOut", "lunch", "getIp"]),
    resetTimers() {
      clearTimeout(this.timer.updateTime);
      clearTimeout(this.timer.updateTimeTrackingStatus);
    },
    getLocation: async function() {
      try {
        this.ip = await this.$api.get("user/ip");
      } catch (e) {
        console.log(e);
      }

      this.location =
        this.ip !== process.env.VUE_APP_OFFICE_IP ? "offsite" : "4241";
    },
    updateTimeTrackingStatus() {
      if (!this.$store.getters.isAuthenticated) {
        this.resetTimers();
        return;
      }

      this.$store.dispatch("timeTracker/fetchStatus");
    },
    updateTime() {
      if (!this.$store.getters.isAuthenticated) {
        this.resetTimers();
        return;
      }

      this.nowUTC = moment().format("YYYY-MM-DD HH:mm:ss");
      this.nowClient = moment
        .tz(moment(), moment.tz.guess())
        .format("YYYY-MM-DD HH:mm:ss");
    },
    clock_In: function() {
      this.$refs.clockTimeDialog.show(TRACK_TIME_ACTIONS.CLOCK_IN);
    },
    clock_Out: async function() {
      this.$refs.clockTimeDialog.show(TRACK_TIME_ACTIONS.CLOCK_OUT);
    },
    lunch: async function() {
      this.$refs.clockTimeDialog.show(TRACK_TIME_ACTIONS.LUNCH);
    },
    onDialogSave() {}
  }
};
</script>

<style scoped>
.dropdown-header {
  background-color: white;
}
.time-track-container {
  margin-left: 0em;
  margin-right: -1em;
  padding-left: 0px;
  padding-right: 0px;
  width: 17em;
}
hr {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>

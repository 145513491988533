var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-input-group', {
    style: _vm.$isMobile ? 'width: 100%;' : 'width: 30em;'
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "type to search"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onSearchClick.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchValue,
      callback: function callback($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-light"
    },
    on: {
      "click": _vm.onSearchClick
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "search"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="breadcrumb">
    <span
      class="breadcrumb-item"
      :key="`${uid}-${index}`"
      v-for="(routeObject, index) in routeRecords"
    >
      <span class="active" v-if="isLast(index)">{{
        getName(routeObject)
      }}</span>
      <b-link href="#" @click="onItemClick(routeObject)" v-else>{{
        getName(routeObject)
      }}</b-link>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters("router", ["routerHistory"]),
    routeRecords: function() {
      return this.$isMobile ? this.routerHistory.slice(-3) : this.routerHistory;
    }
  },
  methods: {
    getName(str) {
      let obj = JSON.parse(str);
      return obj.name;
    },
    isLast(index) {
      return index === this.routeRecords.length - 1;
    },
    onItemClick(str) {
      let obj = JSON.parse(str);

      this.$router.push({ name: obj.name, params: obj.params });
    }
  }
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "nav-item nav-dropdown",
    attrs: {
      "tag": "li",
      "to": _vm.url,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var navigate = _ref.navigate;
        return [_c('span', {
          attrs: {
            "role": "link"
          },
          on: {
            "click": navigate,
            "keypress": function keypress($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return navigate.apply(null, arguments);
            }
          }
        }, [_c('div', {
          staticClass: "nav-link nav-dropdown-toggle",
          on: {
            "click": _vm.handleClick
          }
        }, [_c('i', {
          class: _vm.classIcon
        }), _vm._v(" " + _vm._s(_vm.name) + " ")]), _c('ul', {
          staticClass: "nav-dropdown-items"
        }, [_vm._t("default")], 2)])];
      }
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <nav class="sidebar-nav">
    <VuePerfectScrollbar
      class="scroll-area"
      :settings="psSettings"
      @ps-scroll-y="scrollHandle"
    >
      <b-input-group size="sm">
        <b-form-input
          ref="menuSearch"
          placeholder="type to search menu"
          :class="[searchValue !== '' ? 'highlight' : '']"
          v-model="searchValue"
          @blur="onSearchBlur"
        />

        <b-input-group-append>
          <b-button
            v-if="searchValue !== ''"
            variant="light"
            squared
            @click="searchValue = ''"
          >
            x
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <ul class="nav">
        <template v-for="(item, index) in filteredMenu">
          <template v-if="item.title">
            <SidebarNavTitle
              v-if="item.visible"
              :key="index"
              :name="item.name"
              :classes="item.class"
              :wrapper="item.wrapper"
            />
          </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider :key="index" :classes="item.class" />
          </template>
          <template v-else-if="item.label">
            <SidebarNavLabel
              v-if="item.visible"
              :key="index"
              :name="item.name"
              :url="item.url"
              :icon="item.icon"
              :label="item.label"
              :classes="item.class"
            />
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown
                :key="index"
                :name="item.name"
                :url="item.url"
                :icon="item.icon"
              >
                <template v-for="(childL1, index1) in item.children">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown
                      :key="index1"
                      :name="childL1.name"
                      :url="childL1.url"
                      :icon="childL1.icon"
                    >
                      <li
                        :key="index2"
                        class="nav-item"
                        v-for="(childL2, index2) in childL1.children"
                      >
                        <SidebarNavLink
                          :name="childL2.name"
                          :url="childL2.url"
                          :icon="childL2.icon"
                          :badge="childL2.badge"
                          :variant="childL2.variant"
                          :attributes="childL2.attributes"
                        />
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem :key="index1" :classes="item.class">
                      <SidebarNavLink
                        :name="childL1.name"
                        :url="childL1.url"
                        :icon="childL1.icon"
                        :badge="childL1.badge"
                        :variant="childL1.variant"
                        :attributes="childL1.attributes"
                      />
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem :key="index" :classes="item.class">
                <SidebarNavLink
                  :name="item.name"
                  :url="item.url"
                  :icon="item.icon"
                  :badge="item.badge"
                  :variant="item.variant"
                  :attributes="item.attributes"
                />
              </SidebarNavItem>
            </template>
          </template>
        </template>
      </ul>
      <slot></slot>
    </VuePerfectScrollbar>
  </nav>
</template>

<script>
import SidebarNavDivider from "./SidebarNavDivider";
import SidebarNavDropdown from "./SidebarNavDropdown";
import SidebarNavLink from "./SidebarNavLink";
import SidebarNavTitle from "./SidebarNavTitle";
import SidebarNavItem from "./SidebarNavItem";
import SidebarNavLabel from "./SidebarNavLabel";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "SidebarNav",
  components: {
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel,
    VuePerfectScrollbar
  },
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      searchValue: "",
      filteredMenu: []
    };
  },

  computed: {
    psSettings: () => {
      // ToDo: find better rtl fix
      return {
        maxScrollbarLength: 200,
        minScrollbarLength: 40,
        suppressScrollX:
          getComputedStyle(document.querySelector("html")).direction !== "rtl",
        wheelPropagation: false,
        interceptRailY: styles => ({ ...styles, height: 0 })
      };
    }
  },
  methods: {
    filterMenu(root) {
      this.filteredMenu = [];
      const searchValue = this.searchValue.trim().toLowerCase();

      for (let i = 0; i < root.length; i++) {
        let menuItem = root[i];

        if (searchValue === "") {
          this.filteredMenu.push(menuItem);
          continue;
        }

        if (menuItem.name.toLowerCase().includes(searchValue)) {
          this.filteredMenu.push(menuItem);
        } else {
          if (menuItem.children) {
            menuItem.children = menuItem.children.filter(i =>
              i.name.toLowerCase().includes(searchValue)
            );

            if (menuItem.children.length > 0) this.filteredMenu.push(menuItem);
          }
        }
      }
    },
    onSearchBlur() {},

    scrollHandle() {}
  },
  watch: {
    searchValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        let root = JSON.parse(JSON.stringify(this.navItems));
        this.filterMenu(root);
      }
    },
    navItems() {
      let root = JSON.parse(JSON.stringify(this.navItems));

      this.filterMenu(root);
    }
  }
};
</script>

<style scoped lang="css">
.scroll-area {
  position: absolute;
  height: 100%;
  margin: auto;
}
.form-control {
  border-radius: 0;
  border: 1px solid #fff;
}

.btn-light {
  color: #ccc;
  background-color: #fff;
  border-color: #fff;
}
.highlight {
  background-color: #ffffed;
}
</style>

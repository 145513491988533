var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "breadcrumb"
  }, _vm._l(_vm.routeRecords, function (routeObject, index) {
    return _c('span', {
      key: "".concat(_vm.uid, "-").concat(index),
      staticClass: "breadcrumb-item"
    }, [_vm.isLast(index) ? _c('span', {
      staticClass: "active"
    }, [_vm._v(_vm._s(_vm.getName(routeObject)))]) : _c('b-link', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.onItemClick(routeObject);
        }
      }
    }, [_vm._v(_vm._s(_vm.getName(routeObject)))])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "sidebar-minimizer",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onClick();
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: _vm.classList,
    attrs: {
      "display": _vm.display,
      "mobile": _vm.mobile,
      "type": "button"
    },
    on: {
      "click": _vm.sidebarToggle
    }
  }, [_c('span', {
    staticClass: "navbar-toggler-icon"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
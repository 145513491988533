var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    class: _vm.classList
  }, [_c('input', {
    staticClass: "switch-input form-check-input",
    attrs: {
      "id": _vm.id,
      "disabled": _vm.disabled,
      "required": _vm.required,
      "name": _vm.name,
      "type": "checkbox",
      "true-value": "value",
      "false-value": "uncheckedValue"
    },
    domProps: {
      "checked": _vm.isChecked,
      "value": _vm.value
    },
    on: {
      "change": _vm.handleChange
    }
  }), _vm.label ? [_c('span', {
    staticClass: "switch-slider",
    attrs: {
      "data-checked": _vm.dataOn,
      "data-unchecked": _vm.dataOff
    }
  })] : [_c('span', {
    staticClass: "switch-slider"
  })]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
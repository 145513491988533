<template>
  <!-- no-caret -->
  <AppHeaderDropdown ref="app-header-dd" right class="timetrack-dropdown">
    <template slot="header">
      <font-awesome-icon icon="plus-circle" style="color: gray" />
    </template>
    <template slot="dropdown">
      <b-dropdown-header>
        Quick create
      </b-dropdown-header>

      <b-container class="quick-add-container">
        <b-row no-glutters>
          <b-col>
            <b-button
              block
              squared
              variant="light"
              class="top-menu-btn m-1"
              style="text-align:left;"
              @click="createActivity"
            >
              <font-awesome-icon icon="clipboard-list" style="color: gray;" />
              Activity
            </b-button>
          </b-col>
          <b-col>
            <b-button
              block
              squared
              variant="light"
              class="top-menu-btn m-1"
              style="text-align:left;"
              @click="createTask"
            >
              <font-awesome-icon icon="tasks" style="color: gray;" /> Task
            </b-button>
          </b-col>
        </b-row>
        <b-row no-glutters>
          <b-col>
            <b-button
              block
              squared
              variant="light"
              class="top-menu-btn m-1"
              style="text-align:left;"
              @click="createReceipt"
            >
              <font-awesome-icon icon="receipt" style="color: gray;" /> Receipt
            </b-button>
          </b-col>
          <b-col>
            <b-button
              block
              squared
              variant="light"
              class="top-menu-btn m-1"
              style="text-align:left;"
              @click="createAccount"
            >
              <font-awesome-icon icon="building" style="color: gray;" /> Account
            </b-button>
          </b-col>
        </b-row>
        <b-row no-glutters>
          <b-col>
            <b-button
              block
              squared
              variant="light"
              class="top-menu-btn"
              style="text-align:left;"
              @click="createProject"
            >
              <font-awesome-icon icon="project-diagram" style="color: gray;" />
              Project
            </b-button>
          </b-col>
          <b-col>
            <b-button
              block
              squared
              variant="light"
              class="top-menu-btn m-1"
              style="text-align:left;"
              @click="createContact"
            >
              <font-awesome-icon icon="address-card" style="color: gray;" />
              Contact
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";

import { mapState } from "vuex";

export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  created() {},

  methods: {
    hideDropDown() {
      let classList = this.$refs["app-header-dd"].$el.lastChild.classList;
      classList.remove("show");
      classList.add("hide");
    },
    createActivity() {
      this.hideDropDown();

      this.$router.push({
        name: "Activity submission",
        params: {
          action: "create"
        }
      });
    },
    createTask() {
      this.hideDropDown();

      this.$router.push({
        name: "Task submission",
        params: {
          action: "create"
        }
      });
    },
    createReceipt() {
      this.hideDropDown();

      this.$router.push({
        name: "Receipt submission",
        params: {
          action: "create"
        }
      });
    },
    createAccount() {
      this.hideDropDown();

      this.$router.push({
        name: "Account submission",
        params: {
          action: "create"
        }
      });
    },
    createContact() {
      this.hideDropDown();

      this.$router.push({
        name: "Contact submission",
        params: {
          action: "create"
        }
      });
    },

    createProject() {
      this.hideDropDown();

      this.$router.push({
        name: "Project submission",
        params: {
          action: "create"
        }
      });
    }
  }
};
</script>

<style scoped>
.dropdown-header {
  background-color: white;
}
.quick-add-container {
  padding-left: 0px;
  width: 18em !important;
}
.top-menu-btn {
  color: #23282c;
  background-color: #fff;
  border-color: #fff;
}

.top-menu-btn:hover {
  color: #23282c;
  background-color: #f0f3f5;
  border-color: #f0f3f5;
}
</style>

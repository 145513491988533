<template>
  <div class="app">
    <AppHeader fixed class="exclude-print">
      <b-link class="navbar-brand" to="/">
        <img
          class="navbar-brand-full exclude-print"
          src="/img/brand/logo-koval2.jpg"
          height="20"
          alt="Koval Logo"
        />
      </b-link>

      <SidebarToggler
        class="d-md-down-none p-toggle-absolute"
        display="lg"
        :default-open="true"
      />

      <b-navbar toggleable="lg" :style="!$isMobile ? 'float:right' : ''">
        <SidebarToggler class="d-lg-none" display="md" mobile />

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto p-1">
            <HeaderSearchBox />
            <HeaderDropdownTimeTracker />
            <HeaderDropdownQuickCreate />
            <span v-if="!$isMobile" style="white-space:nowrap">{{
              profile.data.full_name
            }}</span>
            <DefaultHeaderDropdownAccnt />
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <!-- <AsideToggler class="d-none d-lg-block" /> -->
    </AppHeader>

    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :nav-items="menu" />
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main" style="overflow:hidden;">
        <visited-pages class="exclude-print"></visited-pages>
        <!--  <Breadcrumb :list="list" /> -->

        <div class="container-fluid">
          <router-view :key="$route.path" />
        </div>
      </main>
    </div>

    <TheFooter>
      <!--footer-->
      <span>
        <!--Build version: 2.0.55 (2024-10-11 04:01:10 AM)-->
        <!--Build version: 2.0.55 [2024-10-11 04:01:10 AM]-->
      </span>
      <div style="font-size: 12px;color:#c8ced3;">
        <span>Version: 2.0.55 </span>
        <span class="ml-2" ref="build_date">Date:  2024-10-11 04:01:10 AM  </span>
        <span class="ml-2" ref="build_age"> [ {{ buildAge }} ]</span>
      </div>
      <div class="ml-auto" />
    </TheFooter>
  </div>
</template>

<script>
import moment from 'moment'

import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Footer as TheFooter
} from './CoreUI'
//} from "@coreui/vue";

import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import HeaderDropdownTimeTracker from './HeaderDropdownTimeTracker'
import HeaderDropdownQuickCreate from './HeaderDropdownQuickCreate'

import { mapState } from 'vuex'
import { restrictedMenu } from '@/shared/permissions'
import VisitedPages from '../components/VisitedPages.vue'
import HeaderSearchBox from './HeaderSearchBox.vue'

export default {
  name: 'DefaultContainer',
  components: {
    AppHeader,
    AppSidebar,
    TheFooter,

    DefaultHeaderDropdownAccnt,
    HeaderDropdownTimeTracker,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    HeaderDropdownQuickCreate,
    HeaderSearchBox,
    VisitedPages
  },
  data () {
    return {
      menu: [],
      buildAge: 0
    }
  },
  watch: {},
  computed: {
    ...mapState({
      profile: state => state.profile,
      timeTracker: state => state.timeTracker
    }),
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter(route => route.name || route.meta.label)
    }
  },
  created () {},
  mounted () {
    var b = moment(
      this.$refs.build_date.innerHTML.trim(),
      'YYYY-MM-DD hh:mm:ss hA'
    )

    this.buildAge = moment().diff(b, 'minutes') + ' min ago'

    this.menu = restrictedMenu()

    this.$api.post('client-version', { ver: '2.0.55' })

    document.title = `CRM - ${this.profile.data.full_name}`
  },
  methods: {}
}
</script>

<style>
.main .container-fluid {
  padding: 0 13px;
}

.breadcrump {
  margin-bottom: 0;
}

.card-body {
  padding: 0.5rem;
}

body {
  padding-right: 0 !important;
}

.p-toggle-absolute {
  position: absolute;
  z-index: 1000;
  margin: 0.5em;
}

@media print {
  .exclude-print {
    display: none;
  }
}
</style>
